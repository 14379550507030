


export default {
   

    data() {
        return {
            imageUrl:'https://gangcai.oss-cn-beijing.aliyuncs.com',//
            uploadUrl:'api/Common/upload'
         
        };
    },

   

    methods: {
        
    },
};


