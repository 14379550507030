const tools = {
	isPhone(phone) {
		if (!(/^1[3456789]\d{9}$/.test(phone))) {
			return false;
		}
		return true
	},

	getTime(date) {
		let Y = date.getFullYear(),
			M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
			D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()),
			h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()),
			m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()),
			s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
		return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
	},
	timestampToTime(timestamp, type = 1) {
		
		

		
		var date = new Date(timestamp * 1000);
		var Y = date.getFullYear() + "-";
		var M =
			(date.getMonth() + 1 < 10
				? "0" + (date.getMonth() + 1)
				: date.getMonth() + 1) + "-";
		var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
		var h = date.getHours() + ":";
		var m = date.getMinutes() + ":";
		var s = date.getSeconds();

		if (type == 1) {
			return Y + M + D + h + m + s;
		} else {
			return Y + M + D
		}
	}
}
export default tools