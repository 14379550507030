import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  {
    path: '/', //登录
    component: () => import('@/pages/login/login.vue')
  },
  {
    path: '/video',//视频
    component: () => import('@/pages/login/video.vue')
  },
  {
    path: '/agreement',//协议
    component: () => import('@/pages/login/agreement.vue')
  },
  {
    path: '/index', //首页
    component: () => import('@/pages/index/index.vue')
  },
  {
    path: '/gangcaixianhuo', //钢管现货
    component: () => import('@/pages/index/index.vue')
  },
  {
    path: '/caigouxinxi', //采购信息
    component: () => import('@/pages/index/index.vue')
  },
  {
    path: '/jiagongfuwu', //加工服务
    component: () => import('@/pages/index/index.vue')
  },
  {
    path: '/wuliuzhuanxian', //物流专线
    component: () => import('@/pages/index/index.vue')
  },

  {
    path: '/businessDetails', //商家详情
    component: () => import('@/pages/index/businessDetails.vue')
  },
  {
    path: '/buyerCentral', //买家中心
    component: () => import('@/pages/buyerCentral/buyerCentral.vue'),
    children: [
      {
        path: '/',//询价单
        component: () => import('@/pages/buyerCentral/index.vue')
      },
      {
        path: 'requestQuotation',//询价单
        component: () => import('@/pages/buyerCentral/requestQuotation.vue')
      },

      {
        path: 'transactionManagement',//交易管理
        component: () => import('@/pages/buyerCentral/transactionManagement.vue')
      },
      {
        path: 'purchaseListDetails',//交易管理详情
        component: () => import('@/pages/buyerCentral/purchaseListDetails.vue')
      },
    ]
  },
  {
    path: '/sellerCentral', //卖家中心首页
    component: () => import('@/pages/sellerCentral/sellerCentral.vue'),
    children: [
      {
        path: '/',//卖家中心首页
        component: () => import('@/pages/buyerCentral/index.vue')
      },
      {
        path: 'requestQuotation', //询价单
        component: () => import('@/pages/sellerCentral/requestQuotation.vue')
      },
      {
        path: 'purchaseListDetails', //卖家询价单详情
        component: () => import('@/pages/sellerCentral/purchaseListDetails.vue')
      },
      {
        path: 'searchResults', //搜索结果
        component: () => import('@/pages/sellerCentral/searchResults.vue')
      },
      {
        path: 'transactionManagement', //交易管理
        component: () => import('@/pages/sellerCentral/transactionManagement.vue')
      },
      {
        path: 'informationRelease', //信息发布
        component: () => import('@/pages/sellerCentral/informationRelease.vue')
      },
      {
        path: 'modifyInformationRelease', //修改信息发布
        component: () => import('@/pages/sellerCentral/modifyInformationRelease.vue')
      },
      {
        path: 'logisticsInformation', //物流信息
        component: () => import('@/pages/sellerCentral/logisticsInformation.vue')
      },
      {
        path: 'informationManagement', //信息管理
        component: () => import('@/pages/sellerCentral/informationManagement.vue')
      },
      {
        path: 'transfusion', //传音
        component: () => import('@/pages/sellerCentral/transfusion.vue')
      },

      {
        path: 'searchSprites', //搜索精灵
        component: () => import('@/pages/sellerCentral/searchSprites.vue')
      },
      {
        path: 'myMessage', //我的留言
        component: () => import('@/pages/sellerCentral/myMessage.vue')
      },
      {
        path: 'callDetails', //呼叫详情
        component: () => import('@/pages/sellerCentral/callDetails.vue')
      }
    ]
  },
  {
    path: '/personalCenter', //个人中心
    component: () => import('@/pages/index/personalCenter/personalCenter.vue'),
    children: [
      {
        path: '/',//基本信息
        component: () => import('@/pages/index/personalCenter/basicInfo.vue')
      },
      {
        path: 'buyerCompletesInfo',//买家完善资料
        component: () => import('@/pages/index/personalCenter/buyerCompletesInfo.vue')
      },
      {
        path: 'personalCertificate',//个人认证
        component: () => import('@/pages/index/personalCenter/personalCertificate.vue')
      },
      {
        path: 'deposit',//保证金
        component: () => import('@/pages/index/personalCenter/deposit.vue')
      },
      {
        path: 'invoicingInfo',//开票资料
        component: () => import('@/pages/index/personalCenter/invoicingInfo.vue')
      },
      {
        path: 'supplier',//供货商家
        component: () => import('@/pages/index/personalCenter/supplier.vue')
      },
      {
        path: 'subAccount',//子账号
        component: () => import('@/pages/index/personalCenter/subAccount.vue')
      },
      {
        path: 'contact',//联系方式
        component: () => import('@/pages/index/personalCenter/contact.vue')
      },
      {
        path: 'videoManagement',//视频管理
        component: () => import('@/pages/index/personalCenter/videoManagement.vue')
      },
      {
        path: 'changePassword', //修改密码
        component: () => import('@/pages/sellerCentral/changePassword.vue')
      },
      {
        path: 'wxBinding', //绑定微信
        component: () => import('@/pages/sellerCentral/wxBinding.vue')
      },
      {
        path: 'customerService',//联系方式
        component: () => import('@/pages/index/personalCenter/customerService.vue')
      },
      {
        path: 'messageCenter',//消息中心
        component: () => import('@/pages/index/personalCenter/messageCenter.vue')
      },
      {
        path: 'enterpriseCertificate',//企业认证
        component: () => import('@/pages/index/personalCenter/enterpriseCertificate.vue')
      },
      {
        path: 'merchantAuthenticate',//商家认证
        component: () => import('@/pages/index/personalCenter/merchantAuthenticate.vue')
      },
      {
        path: 'companyFunds',//公司资金
        component: () => import('@/pages/index/personalCenter/companyFunds.vue')
      }
    ]
  }

]

const router = new VueRouter({
  mode: 'hash', //'hash',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if ((to.fullPath == '/' && from.fullPath == '/') || to.path == '/agreement') {
//     next()
//   } else {
//     if (from.fullPath == '/' || to.fullPath == '/index') {
//       if (!localStorage.getItem('phone')) {

//         next('/')
//       } else {
//         next()
//       }
//     } else {
//       next()
//     }

//   }

// });

export default router
