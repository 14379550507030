<template>
  <div>
    <router-view />
  </div>
</template>
<script>
  export default {
    // mounted() {
    //     var t = window.devicePixelRatio   // 获取下载的缩放 125% -> 1.25    150% -> 1.5
    //     if (t != 1) {   // 如果进行了缩放，也就是不是1
    //       document.body.style.zoom = -0.6 * t + 1.55;   // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
    //     }
    //     if (t == 1) {
    //       document.body.style.zoom = -0.6 * 1.25 + 1.55;
    //     }
    //   }
  }
</script>
<style lang="scss">
  @import '@/assets/common/common.scss';
</style>