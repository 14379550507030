import Vue from "vue";
import axiosA from "axios";
import { Loading } from "element-ui";

const baseURL = "http://www.gangcaixianhuo.com/api/";
let development = process.env.NODE_ENV == "development" ? true : false;

export const axios = function (method, url, data, loading) {
  const https = axiosA.create({
    baseURL: development ? baseURL : "api/",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded", //'application/json;charset=utf-8',
      token: localStorage.getItem("token") || "",
    },
  });
  if (loading) {
    Loading.service({
      lock: true,
      customClass: "spinner",
    });
  }
  return new Promise((resolve, reject) => {
    https({
      url,
      method,
      data,
    })
      .then((res) => {
        // 此处作用很大，可以扩展很多功能。
        // 比如对接多个后台，数据结构不一致，可做接口适配器
        // 也可对返回日期/金额/数字等统一做集中处理
        if (res.status === 200) {
          resolve(res.data);
        } else {
          // 通过配置可关闭错误提示

          reject(res);
        }
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        if (loading) {
          Loading.service().close();
        }
      });
  });
};

// })
